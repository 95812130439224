import { gql } from '@apollo/client';

import { MINIMAL_USER_FRAGMENT } from 'shared/graphql';

export const GRADUATING_YEAR_METRIC_FRAGMENT = gql`
  fragment GraduatingYearMetric on GraduatingYearsMetric {
    id
    year
    expectedUsersCount
    registeredUsersCount
    registeredUsersPercentage
    missingUsersCount
    missingUsersPercentage
    createdAt
    updatedAt

    createdBy {
      ...MinimalUser
    }
  }

  ${MINIMAL_USER_FRAGMENT}
`;

export const GRADUATING_YEARS_METRICS_QUERY = gql`
  query GraduatingYearsMetrics(
    $first: Int
    $skip: Int
    $filter: GraduatingYearsMetricFilter
    $sort: [GraduatingYearsMetricSort!]
  ) {
    metrics: graduatingYearsMetricsList(first: $first, skip: $skip, filter: $filter, sort: $sort) {
      count
      items {
        ...GraduatingYearMetric
      }
    }
  }

  ${GRADUATING_YEAR_METRIC_FRAGMENT}
`;

export const GRADUATING_YEARS_RANKING_QUERY = gql`
  query GraduatingYearsRanking($filter: GraduatingYearsMetricFilter) {
    metrics: graduatingYearsMetricsList(filter: $filter, sort: { missingUsersPercentage: ASC }) {
      count
      items {
        id
        missingUsersPercentage
      }
    }
  }
`;

const GROUP_REGISTERED_USERS_COUNT_FRAGMENT = gql`
  fragment GroupRegisteredUsersCount on GroupByResponse {
    year: Date
    usersCount: Int
  }
`;

export const REGISTERED_USERS_COUNT_QUERY = gql`
  query RegisteredUsersCount($first: Int!) {
    usersList(
      first: $first
      groupBy: {
        query: {
          id: { as: "usersCount", fn: { aggregate: COUNT } }
          graduatingYear: { as: "year" }
        }
      }
    ) {
      groups {
        ...GroupRegisteredUsersCount
      }
    }
  }
  ${GROUP_REGISTERED_USERS_COUNT_FRAGMENT}
`;

export const GRADUATED_USERS_LIST_PER_YEAR_QUERY = gql`
  query GraduatedUsersListPerYear($first: Int!, $skip: Int, $filter: UserFilter!) {
    usersList(first: $first, skip: $skip, filter: $filter) {
      items {
        ...MinimalUser
      }
    }
  }

  ${MINIMAL_USER_FRAGMENT}
`;
