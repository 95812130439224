import React, { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { Theme, css, useTheme } from '@emotion/react';
import { Box, Avatar, Divider } from '@mui/material';
import { School } from '@material-ui/icons';

import { currentDateTime, processFilestackUrlSrcSet } from '@jebel/utils';
import { DiscountStatus, OrganizationStatus } from '@jebel/constants';

import { OrganizationCardItem } from 'features/explorer/components/ExplorerNationProfile/OrganizationCardItem';
import { PaperLayout } from 'shared/components/layouts';
import {
  DiscountPresentationContent,
  PostFeedLoader,
  RouterLink,
  Typography,
} from 'shared/components/ui';
import { APP_URL, BIRTHDAYS, DISCOUNTS, EVENTS, SIDEBAR_WIDTH, SUPPORTERS } from 'shared/constants';
import {
  MemberCommunitySidebarItemsQuery,
  DiscountListItemFragment as Discount,
  MemberCommunitySidebarItemsQueryVariables,
  UserListItemFragment,
} from 'shared/graphql';
import { ReedemDiscountModal } from 'features/discounts/components/ReedemDiscountModal';
import { useRandomDiscounts } from 'features/discounts/hooks';
import { useRandomOrganizations } from 'features/organizations/hooks';

import { EventCard, UserCard } from './components';
import { MEMBER_COMMUNITY_SIDEBAR_QUERY } from './queries';
import { ModuleContainer } from './components/ModuleContainer';
import { useModalState, useSchoolConfiguration } from '../../hooks';
import { getUsersByBirthday } from '../../utils/user/getUsersByBirthday';

const SUPPORTERS_ICON_SIZE = 35;

const placeholderSx = {
  maxWidth: { xs: '100%', md: `${SIDEBAR_WIDTH}px` },
  margin: '0 auto',
};

export const titleIconCss = (theme: Theme) => css`
  width: ${SUPPORTERS_ICON_SIZE}px;
  height: ${SUPPORTERS_ICON_SIZE}px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: ${theme.palette.secondary.main};
  font-size: ${theme.typography.fontSize + 5}px;
`;

const emptyContainer = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const emptyLabel = (theme: Theme) => css`
  text-align: center;
  font-size: ${theme.typography.body2.fontSize};
  color: ${theme.palette.text.disabled};
`;

const supportersIconCss = theme => ({
  height: `${SUPPORTERS_ICON_SIZE}px`,
  width: `${SUPPORTERS_ICON_SIZE}px`,
  marginRight: theme.spacing(1),
});

export const blockTitleCss = (theme: Theme) =>
  css`
    color: ${theme.palette.secondary.main};
  `;

export const viewAllLinkCSS = (theme: Theme) =>
  css`
    color: ${theme.palette.secondary.main};
  `;

export const headerIconCss = theme => css`
  font-size: ${theme.typography.fontSize + 5}px;
`;

interface CommunitySidebarProps {
  items?: string[] | null | undefined;
  isMainContentLoading?: boolean;
}

export const CommunitySidebar: React.FC<CommunitySidebarProps> = ({
  items = [SUPPORTERS, BIRTHDAYS, DISCOUNTS, EVENTS],
  isMainContentLoading = false,
}) => {
  const { configuration: school } = useSchoolConfiguration();

  const schoolAvatarSource = school?.images?.logo?.downloadUrl ?? undefined;

  const {
    show: isDiscountModalOpen,
    params: selectedDiscount,
    open: onDiscountModalOpen,
    close: onDiscountModalClose,
  } = useModalState<Discount>();

  const dateNow = React.useMemo(() => {
    return currentDateTime();
  }, []);

  const variables: MemberCommunitySidebarItemsQueryVariables = useMemo(() => {
    return {
      dateNow,
      birthdayFilter: {
        birthDate: { is_not_empty: true },
      },
    };
  }, [dateNow]);

  const { data: queryData, loading: queryLoading } = useQuery<
    MemberCommunitySidebarItemsQuery,
    MemberCommunitySidebarItemsQueryVariables
  >(MEMBER_COMMUNITY_SIDEBAR_QUERY, {
    fetchPolicy: 'cache-first',
    variables,
  });

  const { events, users } = React.useMemo(() => {
    return {
      events: queryData?.eventsList?.items || [],
      users: queryData?.usersList?.items || [],
    };
  }, [queryData]);

  const { data: supporters, loading: supportersLoading } = useRandomOrganizations({
    variables: {
      first: 2,
      filter: {
        status: { equals: OrganizationStatus.Active },
      },
    },
  });

  const { data: discounts, loading: discountsLoading } = useRandomDiscounts({
    variables: {
      first: 3,
      filter: {
        status: { equals: DiscountStatus.Active },
        // https://github.com/jebelapp/jebel/issues/1567
        schools: { some: { id: { equals: school.id } } },
      },
    },
  });

  const sortedUsersByBirthday = React.useMemo(
    () => getUsersByBirthday<UserListItemFragment>(users),
    [users],
  );

  const loading = queryLoading || supportersLoading || discountsLoading;

  if (loading || isMainContentLoading) {
    return <PostFeedLoader count={2} />;
  }

  return (
    <PaperLayout sx={placeholderSx}>
      <ReedemDiscountModal
        isOpen={isDiscountModalOpen}
        onClose={onDiscountModalClose}
        discount={selectedDiscount}
      />

      {items?.includes(SUPPORTERS) && (
        <ModuleContainer
          title="Supporters"
          toViewAll={APP_URL.user.organizations.index}
          leftBox={
            <Avatar
              variant="circular"
              css={supportersIconCss}
              src={schoolAvatarSource}
              srcSet={processFilestackUrlSrcSet(schoolAvatarSource, {
                compress: true,
                resize: {
                  width: SUPPORTERS_ICON_SIZE,
                  height: SUPPORTERS_ICON_SIZE,
                  fit: 'crop',
                },
              })}
            >
              <School />
            </Avatar>
          }
        >
          {supporters.map(supporter => (
            <OrganizationCardItem key={supporter.id} organization={supporter} />
          ))}

          {supporters.length === 0 && (
            <Box css={emptyContainer}>
              <Typography css={emptyLabel}>No supporters found</Typography>
            </Box>
          )}
        </ModuleContainer>
      )}

      {items?.includes(DISCOUNTS) && (
        <ModuleContainer title="Discounts">
          {discounts.map(discount => (
            <DiscountPresentationContent
              key={discount?.id}
              onRedeem={() => onDiscountModalOpen(discount)}
              discount={discount}
              withRedeem
            />
          ))}

          {discounts.length === 0 && (
            <Box css={emptyContainer}>
              <Typography css={emptyLabel}>No active discounts</Typography>
            </Box>
          )}
        </ModuleContainer>
      )}

      {items?.includes(EVENTS) && !!events.length && (
        <ModuleContainer
          title="Upcoming events"
          toViewAll={APP_URL.user.events.index}
          icon="EventNote"
        >
          {events.map(event => (
            <EventCard key={event?.id} event={event} />
          ))}

          {events.length === 0 && (
            <Box css={emptyContainer}>
              <Typography css={emptyLabel}>No upcoming events</Typography>
            </Box>
          )}
        </ModuleContainer>
      )}

      {items?.includes(BIRTHDAYS) && !!users.length && (
        <ModuleContainer title="Upcoming birthdays" icon="Cake">
          {sortedUsersByBirthday?.map(user => (
            <UserCard key={user?.id} user={user} />
          ))}

          {sortedUsersByBirthday.length === 0 && (
            <Box css={emptyContainer}>
              <Typography css={emptyLabel}>No upcoming birthdays</Typography>
            </Box>
          )}
        </ModuleContainer>
      )}
    </PaperLayout>
  );
};

export const BlockDivider = () => {
  const theme: any = useTheme();
  return <Divider sx={{ border: `1px solid ${theme.palette.borderGrey}` }} />;
};

interface ViewAllLinkProps {
  to: string;
  isAbsolute?: boolean | undefined;
}

export const ViewAllLink = ({ isAbsolute = false, to }: ViewAllLinkProps) => {
  return (
    <RouterLink css={viewAllLinkCSS} absolute={isAbsolute!} to={to}>
      View all
    </RouterLink>
  );
};
