import { Fragment } from 'react';
import pluralize from 'pluralize';
import { AvatarGroup } from '@material-ui/lab';
import { css, Theme, useTheme } from '@emotion/react';
import { Box, Button, Typography } from '@material-ui/core';
import { Stars } from '@material-ui/icons';
import { Skeleton } from '@mui/material';

import { useCrudPermissions, useModalState } from 'shared/hooks';
import { PaperLayout } from 'shared/components/layouts';
import { Modal } from 'shared/components/ui';
import { InviteMemberModal } from 'shared/components/dialogs/InviteModal/components/InviteMemberModal';
import { DountChart } from 'shared/components/charts';
import { ClassViewDedailsModal } from 'admin-features/class-scorecard/components/ClassViewDedailsModal';
import { UserAvatar } from 'shared/components/symbols';
import { useGraduatedUsersListPerYear, useGraduatingYearsRanking } from 'features/scorecard/hooks';

const containerCSS = css`
  display: grid;
  grid-template-columns: 7fr 3fr;
  align-items: center;
  padding: 1.5rem;
  gap: 1rem;
`;

const contentCSS = css`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 0.8rem;
`;

const classYearCSS = css`
  font-weight: 400;
  line-height: 2.6rem;
`;

const graduatesCountCSS = css`
  font-weight: 500;
`;

const rankedBoxCSS = (theme: Theme) => css`
  display: flex;
  color: ${theme.palette.primary.light};
  gap: 0.5rem;
`;

const chartContainerCSS = css`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 0.5rem;
`;

const MAX_USER_AVATARS_SHOW = 4;

interface Props {
  id: string;
  position: number;
  year: string;
  registeredUsersCount: number;
  registeredUsersPercentage: number;
  missingUsersCount: number;
  missingUsersPercentage: number;
}

export function ClassesListItem({
  id,
  position,
  year,
  registeredUsersCount,
  registeredUsersPercentage,
  missingUsersCount,
  missingUsersPercentage,
}: Props) {
  const { palette } = useTheme();
  const { membersPermissions } = useCrudPermissions();
  const { count: maxRank } = useGraduatingYearsRanking();

  const { show: isInviteModalOpen, open: onModalOpen, close: onModalClose } = useModalState();

  const {
    show: showDetailsModal,
    open: openDetailsModal,
    close: closeDetailsModal,
  } = useModalState();

  const { correspondingUsersList, correspondingUsersLoading } = useGraduatedUsersListPerYear(
    Number(year),
  );

  return (
    <Fragment>
      {isInviteModalOpen && (
        <Modal
          dialogProps={{
            open: isInviteModalOpen,
            onClose: onModalClose,
            fullWidth: true,
          }}
          titleProps={{ title: 'Invite friends' }}
          isVisibleDivider
          dialogContentWidth={280}
        >
          <InviteMemberModal onClose={onModalClose} />
        </Modal>
      )}

      {showDetailsModal && (
        <Modal
          dialogProps={{
            open: showDetailsModal,
            onClose: closeDetailsModal,
          }}
          titleProps={{ title: 'View Class Details' }}
        >
          <ClassViewDedailsModal classId={id} onModalClose={closeDetailsModal} />
        </Modal>
      )}

      <PaperLayout css={containerCSS}>
        <Box css={contentCSS}>
          <Box display="flex" flexWrap="wrap" gridGap={4}>
            <Typography css={classYearCSS} variant="subtitle2" color="primary">
              Class of {year}
            </Typography>

            {correspondingUsersLoading ? (
              <Box display="flex" flexDirection="row" gridGap="0.25rem">
                {Array.from({ length: MAX_USER_AVATARS_SHOW }).map((_, index) => (
                  <Skeleton key={index} variant="circular" width={40} height={40} />
                ))}
              </Box>
            ) : (
              <AvatarGroup max={MAX_USER_AVATARS_SHOW}>
                {correspondingUsersList.map(user => (
                  <UserAvatar iconSize="small" key={user.id} user={user} />
                ))}
              </AvatarGroup>
            )}
          </Box>

          <Box css={rankedBoxCSS}>
            <Stars fontSize="small" />

            <Typography variant="body2" css={graduatesCountCSS}>
              Ranked {position} of {maxRank}
            </Typography>
          </Box>

          <Typography variant="body2">
            <span>{registeredUsersPercentage}% Registered&nbsp;</span>
            <span>{pluralize('user', registeredUsersCount)}&nbsp;</span>
            <span>({registeredUsersCount})</span>
          </Typography>

          {membersPermissions.add && (
            <Button
              variant="contained"
              color="secondary"
              size="small"
              disableElevation
              onClick={onModalOpen}
            >
              Invite Alumni
            </Button>
          )}
        </Box>

        <Box css={chartContainerCSS} onClick={openDetailsModal}>
          <DountChart
            data={[
              {
                label: 'Registered users',
                value: registeredUsersPercentage,
                color: palette.primary.main,
              },
              {
                label: 'Missing alumni',
                value: missingUsersPercentage,
                color: palette.secondary.main,
              },
            ]}
          />

          <Typography color="secondary" variant="body2">
            <span>{missingUsersPercentage}% Missing&nbsp;</span>
            <span>{pluralize('alumni', missingUsersCount)}&nbsp;</span>
            <span>({missingUsersCount})</span>
          </Typography>
        </Box>
      </PaperLayout>
    </Fragment>
  );
}
