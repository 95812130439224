import { useMemo } from 'react';
import pluralize from 'pluralize';

import { createFilterBuilder } from '@jebel/utils';

import { Typography, AsyncContent } from 'shared/components/ui';
import { useSearchContext } from 'shared/features/search';
import { InfiniteScroll } from 'shared/features/infinite-scroll';
import { GraduatingYearsMetricFilter } from 'shared/graphql';

import { useGraduatingYearsPaginated } from '../../hooks';
import { ClassesListItem } from './ClassesListItem';
import { AddAdsToContent } from '../../../advertising/utils/addAdsToContent';

export function ClassesList() {
  const { sortOption: currentSort, filter: currentSelectedYear } = useSearchContext();

  const filter = useMemo(() => {
    const filter = createFilterBuilder<GraduatingYearsMetricFilter>();
    const selectedYear = Number(currentSelectedYear);

    if (!Number.isNaN(selectedYear)) {
      filter.and({ year: { equals: String(selectedYear) } });
    }

    return filter.build();
  }, [currentSelectedYear]);

  const {
    data: yearsList,
    count: yearsCount,
    loading,
    refreshing,
    hasMore,
    fetchMore,
  } = useGraduatingYearsPaginated({
    variables: {
      sort: currentSort,
      filter,
    },
  });

  const renderYears = useMemo(() => {
    return yearsList.map(year => {
      const registeredUsersPercentage = Math.round(year.registeredUsersPercentage ?? 0);
      const missingUsersPercentage = Math.round(year.missingUsersPercentage ?? 0);

      return (
        <ClassesListItem
          key={year.id}
          id={year.id as string}
          registeredUsersCount={year.registeredUsersCount ?? 0}
          registeredUsersPercentage={registeredUsersPercentage}
          missingUsersCount={year.missingUsersCount ?? 0}
          missingUsersPercentage={missingUsersPercentage}
          position={year.position ?? 0}
          year={String(year.year)}
        />
      );
    });
  }, [yearsList]);

  return (
    <AsyncContent loading={loading || refreshing}>
      <InfiniteScroll hasMore={hasMore} fetchMore={fetchMore}>
        <Typography color="secondary">
          {yearsCount} total class {pluralize('year', yearsCount)}
        </Typography>

        <AddAdsToContent items={renderYears} />
      </InfiniteScroll>
    </AsyncContent>
  );
}
