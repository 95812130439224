import { useMemo, useState } from 'react';

import { DISCOUNT_STATUSES } from '@jebel/constants/dist/discounts';
import { createFilterBuilder } from '@jebel/utils';

import { PAGE_SIZE, SELECT_OPTION_ALL } from 'shared/constants';
import { useSearchContext } from 'shared/features/search';
import { InputMaybe, DiscountFilter, useDiscountsListQuery } from 'shared/graphql';
import { useResponsive, useSchoolConfiguration } from 'shared/hooks';

export function useDiscountsList() {
  const [localZipQuery, setLocalZipQuery] = useState('');

  const { searchQuery, sortOption, filter: rawFilter, zipQuery } = useSearchContext();
  const { data: school } = useSchoolConfiguration();
  const { isMobile } = useResponsive();

  const filter: InputMaybe<DiscountFilter> = useMemo(() => {
    const filter = createFilterBuilder<DiscountFilter>({
      status: { equals: DISCOUNT_STATUSES.active },
    });

    if (rawFilter !== SELECT_OPTION_ALL) {
      filter.and({ category: { equals: rawFilter } });
    }

    if (school) {
      // https://github.com/jebelapp/jebel/issues/1567
      filter.and({ schools: { some: { id: { equals: school.id } } } });
    }

    if (searchQuery) {
      const searchFilter = createFilterBuilder<DiscountFilter>();

      searchFilter.or({ title: { contains: searchQuery } });
      searchFilter.or({ category: { contains: searchQuery } });
      searchFilter.or({ organization: { name: { contains: searchQuery } } });
      searchFilter.or({ terms: { contains: searchQuery } });

      // Search by the specified fields.
      // https://8base-dev.atlassian.net/browse/JEB-1465
      filter.and(searchFilter);
    }

    if (!isMobile && zipQuery) {
      filter.and({
        organization: {
          location: { some: { address: { some: { zip: { equals: zipQuery } } } } },
        },
      });
    }

    if (isMobile && localZipQuery) {
      filter.and({
        organization: {
          location: { some: { address: { some: { zip: { equals: localZipQuery } } } } },
        },
      });
    }

    return filter.build();
  }, [isMobile, localZipQuery, rawFilter, searchQuery, zipQuery]);

  const {
    data: response,
    loading,
    refetch,
    fetchMore,
  } = useDiscountsListQuery({
    variables: {
      first: PAGE_SIZE,
      filter,
      sort: sortOption,
    },
  });

  const data = response?.discounts.items ?? [];
  const count = response?.discounts.count ?? 0;

  const hasMore = data.length < count;

  return {
    data,
    /** @deprecated Use `data` instead. */
    items: data,
    count,
    hasMore,

    setLocalZipQuery,
    loading,
    fetchMore,
    refetch,
  };
}
