/* eslint-disable react/no-array-index-key */
import { Fragment, useCallback } from 'react';
import { css, Theme } from '@emotion/react';
import { Dialog, DialogTitle, DialogContent, MenuItem, Divider, Box } from '@material-ui/core';
import { ArrayHelpers, FieldArray, FormikHelpers, FormikProps, FormikValues } from 'formik';
import { Avatar } from '@mui/material';

import { processFilestackUrlSrcSet } from '@jebel/utils';

import { useCreateOrganization } from 'features/organizations/hooks';
import {
  Form,
  FormTextField,
  FormSelect,
  Icon,
  Button,
  FormPhoneFieldV2 as FormPhoneField,
  Typography,
  ImageInput,
  MediaInput,
  AutocompleteField,
} from 'shared/components/ui';
import { DISCOUNTS_OPTIONS, US_STATES_OPTIONS } from 'shared/constants';
import { composeValidators, maxTextSymbols } from 'shared/utils/form';
import { sendToSentry } from 'shared/utils/sentry';
import { ResultFile, ResultFileValue } from 'shared/types/files';
import {
  OrganizationCreateInput,
  Organization_OrganizationLocationCreateInput,
} from 'shared/graphql';
import {
  BUSINESS_FORM_LOCATION_ADDITIONAL_FIELDS,
  BUSINESS_FORM_LOCATION_BLANK,
  BusinessFormLocation,
} from 'shared/features/organizations';
import { Apartment } from '@material-ui/icons';

const ADVERTISING_DOMAIN = process.env.REACT_APP_ADVERTISING_DOMAIN;
const ORGANIZATION_DESCRIPTION_MAX_LENGTH = 1000;

const ORGANIZATION_LOGO_SIZE = 80;
const DROP_ZONE_HEIGHT = 150;

const LOCATION_FIELDS = [
  { name: 'Location Name', field: 'locationName' },
  { name: 'Address Line', field: 'address.street1' },
  { name: 'City', field: 'address.city' },
];

const modalContainerCss = theme => css`
  padding: ${theme.spacing(1)}px 0px;
`;

const formCss = theme => css`
  display: grid;
  grid-row-gap: ${theme.spacing(1.25)}px;
`;

const formRowCss = theme => css`
  display: grid;
  grid-template-columns: auto min-content;
  grid-gap: ${theme.spacing(1.25)}px;

  ${theme.breakpoints.down('md')} {
    display: flex;
    flex-direction: column-reverse;
  }
`;

const formRowInnerCss = theme => css`
  display: grid;
  grid-row-gap: ${theme.spacing(1.25)}px;
`;

const formBlockCss = css`
  display: grid;
  grid-auto-flow: row;
  grid-row-gap: 15px;
`;

const addressRowCss = theme => css`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${theme.spacing(1.25)}px;

  ${theme.breakpoints.down('md')} {
    grid-template-columns: 1fr;
  }
`;

const dropZoneContainer = theme => css`
  background-color: ${theme.palette.background.light};
  border: 1px dashed #bbbbbb;
  height: ${DROP_ZONE_HEIGHT}px;
  display: grid;
  grid-template-rows: min-content min-content;
  align-items: center;
  justify-items: center;
  align-content: center;
  cursor: pointer;
`;

const footerButtonsCss = theme => css`
  display: grid;
  grid-auto-flow: column;
  justify-content: end;
  grid-column-gap: ${theme.spacing(2.25)}px;
`;

const logoBoxCss = (theme: Theme) => css`
  width: ${ORGANIZATION_LOGO_SIZE}px;
  height: ${ORGANIZATION_LOGO_SIZE}px;
  border-radius: ${theme.spacing(0.5)}px;
  border: 1px solid ${theme.palette.divider};
  color: ${theme.palette.secondary.main};
  display: grid;
  align-items: center;
  justify-items: center;
  cursor: pointer;
  gap: 0.5rem;

  ${theme.breakpoints.down('md')} {
    width: 100%;
  }
`;

const cancelButtonCss = (theme: Theme) => css`
  padding: ${theme.spacing(1)}px ${theme.spacing(2)}px;
  color: ${theme.palette.text.secondary};
`;

const dropZoneIconCss = (theme: Theme) => css`
  font-size: ${theme.typography.fontSize}px;
  fill: ${theme.palette.primary.light};
  width: 24px;
  height: 24px;
`;

const addLocationButtonCss = (theme: Theme) => css`
  color: ${theme.palette.primary.main};
  font-weight: 500;
  display: flex;
  justify-content: flex-start;
  text-transform: capitalize;
`;

const dropZoneTextCss = (theme: Theme) => css`
  font-size: 16px;
  font-weight: 500;
  color: ${theme.palette.primary.light};
`;

const INITIAL_VALUES: { location: BusinessFormLocation[] } = {
  location: [BUSINESS_FORM_LOCATION_BLANK],
};

const addLocation = (formikPush: ArrayHelpers['push']) => () => {
  formikPush(BUSINESS_FORM_LOCATION_BLANK);
};

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

interface FormValues {
  description?: string;
  industry?: string;
  name?: string;
  logo?: ResultFile | null;
  media?: ResultFile[] | null;
  location?: BusinessFormLocation[];
}

export function OrganizationClaimModal({ isOpen, onClose }: Props) {
  const { organizationCreate } = useCreateOrganization();

  const onChangeMedia = useCallback(
    (setFieldValue: FormikHelpers<FormData>['setFieldValue']) =>
      (postMedia: ResultFileValue | null) => {
        if (Array.isArray(postMedia)) {
          setFieldValue('media', postMedia);
        }
      },
    [],
  );

  const onCreateOrganization = async (form: FormValues) => {
    const locations = form.location ?? [];

    const payload: OrganizationCreateInput = {
      name: form.name as string,
      description: form.description as string,
      industry: form.industry as string,
    };

    if (locations.length > 0) {
      payload.location = {
        create: locations.map<Organization_OrganizationLocationCreateInput>(location => {
          return {
            locationName: location.locationName as string,
            operationalHours: location.operationalHours as string,
            contactName: location.contactName as string,
            phoneNumber: location.phoneNumber,
            email: location.email as string,
            link: location.link as string,
            address: { create: [location.address] },
          };
        }),
      };
    }

    if (form?.logo) {
      payload.logo = { create: { fileId: form.logo?.fileId } };
    }

    if (form?.media?.length) {
      payload.media = {
        create: form?.media?.map(media => ({ fileId: media.fileId })),
      };
    }

    try {
      const response = await organizationCreate(payload, ['OrganizationsList']);
      const id = response?.data?.claimOrganization.id;

      if (!id) {
        throw new Error('No ID returned from the request');
      }

      onClose();
      switchToAdvertising(id);
    } catch (err) {
      sendToSentry(err);
    }
  };

  const switchToAdvertising = (id: string) => {
    const url = new URL('/business-details/business-information', ADVERTISING_DOMAIN);

    url.searchParams.set('organization', id);
    window.location.assign(url);
  };

  const deleteLocation = useCallback(
    (remove: ArrayHelpers['remove'], index: number) => () => {
      remove(index);
    },
    [],
  );

  const handleSetImage = useCallback(
    (setFieldValue: FormikProps<FormikValues>['setFieldValue']) => (value: ResultFile | null) => {
      setFieldValue('logo', value);
    },
    [],
  );

  return (
    <Dialog open={isOpen} fullWidth maxWidth="sm" onClose={onClose}>
      <DialogTitle>Claim Business </DialogTitle>
      <Divider
        absolute={false}
        flexItem={false}
        light={false}
        orientation="horizontal"
        variant="fullWidth"
      />
      <DialogContent>
        <Box css={modalContainerCss}>
          <Form oldCss={formCss} initialValues={INITIAL_VALUES} onSubmit={onCreateOrganization}>
            {({ isSubmitting, setFieldValue, values }) => (
              <Fragment>
                <FormTextField
                  inputProps={{
                    color: 'primary',
                    label: 'Business Name',
                    variant: 'outlined',
                    required: true,
                  }}
                  fieldProps={{ name: 'name', required: true }}
                />
                <Box css={formRowCss}>
                  <Box css={formRowInnerCss}>
                    <FormSelect
                      selectProps={{
                        children: DISCOUNTS_OPTIONS?.slice(1).map(item => (
                          <MenuItem key={item.value} value={item.value}>
                            {item.label}
                          </MenuItem>
                        )),
                        variant: 'outlined',
                        label: 'Industry',
                      }}
                      fieldProps={{ name: 'industry', required: true }}
                    />

                    <FormTextField
                      inputProps={{
                        color: 'primary',
                        label: 'Description',
                        variant: 'outlined',
                        multiline: true,
                        rows: '5',
                        required: true,
                      }}
                      fieldProps={{
                        name: 'description',
                        required: true,
                        validate: composeValidators(value =>
                          maxTextSymbols(value, ORGANIZATION_DESCRIPTION_MAX_LENGTH),
                        ),
                      }}
                    />
                  </Box>

                  <ImageInput
                    name="logo"
                    maxFiles={1}
                    showDescription={false}
                    value={values.logo}
                    onChange={handleSetImage(setFieldValue)}
                    onDelete={() => setFieldValue('logo', undefined)}
                    customPicker={
                      <Box display="flex" flexDirection="column" gridGap="0.5rem">
                        <Box
                          css={logoBoxCss}
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          alignContent="center"
                        >
                          <Apartment />
                        </Box>

                        <Typography color="secondary" variant="caption" align="center">
                          Add Image
                        </Typography>
                      </Box>
                    }
                    customPreview={
                      <Box display="flex" flexDirection="column" justifyContent="center">
                        <Avatar
                          css={css`
                            width: 80px;
                            height: 80px;
                            cursor: pointer;
                          `}
                          variant="square"
                          src={values.logo?.downloadUrl ?? undefined}
                          srcSet={processFilestackUrlSrcSet(values.logo?.downloadUrl ?? undefined, {
                            resize: {
                              width: 80,
                              height: 80,
                            },
                          })}
                        />
                      </Box>
                    }
                  />
                </Box>
                <FieldArray name="location">
                  {({ push, remove }) => {
                    const locations = values.location as BusinessFormLocation[];

                    return (
                      <Fragment>
                        {locations.map((field, index) => {
                          const isRequiredLocation = index === 0;

                          return (
                            <Fragment key={`location.${index}`}>
                              <Box
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                              >
                                <Typography variant="subtitle5">Location #{index + 1}</Typography>
                                {!isRequiredLocation && (
                                  <Button onClick={deleteLocation(remove, index)}>
                                    <Icon name="DeleteForever" />
                                  </Button>
                                )}
                              </Box>
                              <Box css={formBlockCss}>
                                {LOCATION_FIELDS.map(item => (
                                  <Fragment key={item.name}>
                                    <FormTextField
                                      inputProps={{
                                        color: 'primary',
                                        label: item.name,
                                        variant: 'outlined',
                                        required: true,
                                      }}
                                      fieldProps={{
                                        name: `location.${index}.${item.field}`,
                                        required: true,
                                      }}
                                    />
                                  </Fragment>
                                ))}
                              </Box>
                              <Box css={addressRowCss}>
                                <AutocompleteField
                                  label="State"
                                  name={`location.${index}.address.state`}
                                  options={US_STATES_OPTIONS}
                                  required
                                />

                                <FormTextField
                                  inputProps={{
                                    color: 'primary',
                                    label: 'ZIP Code',
                                    variant: 'outlined',
                                    required: true,
                                  }}
                                  fieldProps={{
                                    name: `location.${index}.address.zip`,
                                    required: true,
                                  }}
                                />
                              </Box>

                              <Box css={formBlockCss}>
                                {BUSINESS_FORM_LOCATION_ADDITIONAL_FIELDS.map(item => {
                                  if (item.type === 'phone') {
                                    return (
                                      <FormPhoneField
                                        key={item.name}
                                        inputProps={{
                                          label: 'Phone Number',
                                          variant: 'outlined',
                                          required: item.required,
                                        }}
                                        fieldProps={{
                                          name: `location.${index}.${item.name}`,
                                          validate: item.validate,
                                          required: item.required,
                                        }}
                                      />
                                    );
                                  }

                                  if (item.type === 'description') {
                                    return (
                                      <FormTextField
                                        key={item.name}
                                        inputProps={{
                                          label: item.label,
                                          multiline: true,
                                          rows: '3',
                                          rowsMax: '3',
                                          variant: 'outlined',
                                          required: item.required,
                                        }}
                                        fieldProps={{
                                          name: `location.${index}.${item.name}`,
                                          validate: item.validate,
                                          required: item.required,
                                        }}
                                      />
                                    );
                                  }

                                  return (
                                    <FormTextField
                                      key={item.name}
                                      inputProps={{
                                        label: item.label,
                                        variant: 'outlined',
                                        required: item.required,
                                      }}
                                      fieldProps={{
                                        name: `location.${index}.${item.name}`,
                                        validate: item.validate,
                                        required: item.required,
                                      }}
                                    />
                                  );
                                })}
                              </Box>
                            </Fragment>
                          );
                        })}

                        <Button onClick={addLocation(push)} css={addLocationButtonCss}>
                          Add another location
                        </Button>
                      </Fragment>
                    );
                  }}
                </FieldArray>
                <MediaInput
                  maxFiles={5}
                  descriptionPlacement="top"
                  onChange={onChangeMedia(setFieldValue)}
                >
                  <Box css={dropZoneContainer}>
                    <Icon css={dropZoneIconCss} name="FileUpload" variant="filled" />
                    <Typography css={dropZoneTextCss} variant="inherit">
                      Upload pictures or videos here
                    </Typography>
                  </Box>
                </MediaInput>
                <Box css={footerButtonsCss}>
                  <Button variant="text" css={cancelButtonCss} onClick={onClose}>
                    Cancel
                  </Button>
                  <Button color="primary" variant="contained" type="submit" loading={isSubmitting}>
                    Claim Business
                  </Button>
                </Box>
              </Fragment>
            )}
          </Form>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
